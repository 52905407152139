/*
* two-column-layout
*/
.two-column-layout {
    .column{
        &:last-child{
            border-left: 1px solid #d4e1e7;
        }
        float: left;
        width: 50%;
        padding: 0 40px;
        text-align: center;
        figure{
          margin-bottom: 40px;
        }
        h5{
          margin-bottom: 30px;
        }
        p{
          font-family: "Dosis", sans-serif;
          font-weight: 400;
          font-size: rem-calc(14);
          letter-spacing: 0.75px;
        }
    }
}

@include media-breakpoint-down(sm) {
  .two-column-layout{
    .column{
      &:last-child{
        border-left: none;
        border-top: 1px solid #d4e1e7;
      }
      float: none;
      width: 100%;
      padding: 50px 10px 25px;
    }
  }
}

