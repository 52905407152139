/*
* box
*/
.box {
    position: relative;
    background: #133246;
    max-width: 276px;
    padding: 45px 20px 25px;
    text-align: center;
    margin-bottom: 62px;
    h5{
        color: #fff;
        margin-bottom: 15px;
        letter-spacing: 0.75px;
    }
    p{
        color: #8ca7b9;
        margin-bottom: 5px;
        a{
             color: inherit;
        }
    }
    address{
        margin-bottom: 5px;
        p{
            font-weight: 300;
            font-size: rem-calc(14);
            color: #8ca7b9;
            margin-bottom: 0px;
        }
    }
    a{
         font-weight: 300;
         font-size: rem-calc(14);
    }
    .country-flag {
        position: absolute;
        top: -17px;
        left: 50%;
        margin-left: -23px;
    }
}


@include media-breakpoint-down(sm) {
  .box {
    max-width: 100%;
  }
}
