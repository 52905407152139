
body{
  font-weight: 300;
}

h1,h2,h3,h4,h5,h6 {
    text-transform: uppercase;
    margin-bottom: 30px;
    letter-spacing: 1px;
}
a{
    &:hover {
        text-decoration: none;
    }
}


#container { min-width: 320px; overflow-x: hidden;}

#header{
  &.scroll{
    background: #fff;
    height: 83px;
          transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;

  }
}

#header {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
              transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;

    .container-fluid {
        padding: 0 30px;
        position: relative;
    }
}
@include media-breakpoint-down(sm) {
    #header{
        .container-fluid{
            padding: 0 15px;
        }
    }
}


@include media-breakpoint-down(sm) {
    .container {
        width: 100%;
    }
}




