/*
* logo
*/
.logo {
    float: left;
    padding-top: 12px;
}

@include media-breakpoint-down(md) {
    .logo {
        width: 65%;
    }
}




/*
* navigation
*/
#navigation{
    float: right;
    ul{
        padding: 0;
        margin: 0;
          transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;

    }
    li{
        &.philosophy, &:last-child{
            a{
                &:before{
                    border-right: none;
                }
            }
        }
        &:hover {
            border-top: 5px solid #11c0e6;
        }
        &.philosophy {
            &:before{
                background: url("../images/image1.png") no-repeat;
                content: "";
                height: 95px;
                width: 166px;
                position: absolute;
                top: 0;
                left: 0;
            }
            &:hover {
                border-top: none;
            }
            border-top: none;
            a{
                color: #fff;
                padding-top: 35px;
            }
        }
        border-top: 5px solid transparent;
        float: left;
        list-style: none;
        position: relative;
        a{
            &:hover {
                text-decoration: none;
            }
            &:before{
                border-right: 1px solid #d8dce1;
                content: "";
                height: 30px;
                width: 1px;
                position: absolute;
                top: 22px;
                right: 0;
            }
            position: relative;
            font-weight: 700;
            color: #051d32;
            font-size: rem-calc(11);
            line-height: 1.45;
            letter-spacing: 1px;
            text-transform: uppercase;
            padding: 30px 30px 20px;
            display: block;
            text-align: center;
            display: block;
        }
    }
}


@include media-breakpoint-down(lg) {
    #navigation {
        li{
            &.philosophy{
                a{
                    padding: 35px 30px 20px;
                }
            }
            a{
                padding: 35px 25px 20px;
            }
        }
    }
}

/*
@include media-breakpoint-down(md) {
    #navigation{
        li{
            &.philosophy {
                &:before{
                    background: url("../images/image1-mobile.png") no-repeat;
                    width: 130px;
                    height:78px;
                }
                a{
                    padding: 29px 12px 20px;
                }
            }
            a{
                &:before{
                    top: 20px;
                    height: 25px;
                }
                padding: 25px 12px 20px;
            }
        }
    }
}
*/

@include media-breakpoint-down(md) {
    #navigation {
        ul{
          background: #fff;
          position: absolute;
          top: 0px;
          right: -1000px;
          width: 100%;
          display: none;
          text-align: left;
          transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        }
        li{
            &.menu {
                background: #f8f9f9;
                padding: 25px 40px;
                font-family: "Dosis";
                font-weight: 700;
                font-size: rem-calc(20);
                color: #003960;
                text-transform: uppercase;
            }
            &.philosophy{
                &:before{
                    display: none;
                }
                a{
                    color: #051d32;
                    padding: 25px 30px;
                }
            }
            &:hover {
                border-top: none;
            }
            &:last-child{
                border-bottom: none;
            }
            float: none;
            width: 100%;
            border-bottom: 2px solid #ebebeb;
            border-top: none;
            a{
                &:hover {
                     border-left: 10px solid #11c0e6;
                     color: #11c0e6;
                }
                &:before{
                    border-right: none;
                }
                 border-left: 10px solid transparent;
                 text-align: left;
                 padding: 25px 30px;
            }
        }
    }
}





/*
* menu-btn
*/
.menu-btn {
    &:focus{
        border: none;
        outline: none;
    }
    display: none;
    background: transparent;
    border: none;
    width: 32px;
    height: 28px;
    padding: 0;
    position: absolute;
    top: 30px;
    right: 28px;
    cursor: pointer;
    z-index: 2;
    .line {
        &:last-child{
            margin-bottom: 0;
        }
        display: block;
        background: #00538b;
        width: 100%;
        height: 6px;
        margin-bottom: 5px;
        border-radius: 3px;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    }
}

@include media-breakpoint-down(md) {
    .menu-btn{
        display: block;
    }
}



/*
* navigation -active
*/
#navigation{
    &.active {
        ul{
            display: block;
            right: 0;
            transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
            -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
            -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
            -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
            -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        }
        .menu-btn{
            .line{
                &:first-child {
                    left: 0;
                    transform: rotate(40deg);
                }
                &:last-child{
                    right: 0;
                    transform: rotate(-40deg);
                }
                &:first-child + .line{
                    background: transparent;
                }
                position: absolute;
                top: 10px;
                background: #11c0e6;
                transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
                -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
                -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
                -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
                -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
            }
        }
    }
}




