/*
* dropdown-content
*/
.dropdown-content {
  margin: 0;
  padding: 0;
  li{
    &.open {
      &:after {
        background-position: left bottom;
      }
      .inner-content {
        display: block;
        padding-top: 20px;
      }
    }
    &:after {
      background: url("../images/arrow-sprit.svg") no-repeat;
      background-position: left top;
      width: 13px;
      height: 7px;
      content: "";
      position: absolute;
      top: 43px;
      right: 43px;
    }
    list-style: none;
    padding: 30px;
    margin-bottom: 15px;
    box-shadow: 0px 0px 6px -2px rgba(0,0,0,.3);
    background-color: #fff;
    position: relative;
  }
  h5{
    color: #003960;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 0px;
    vertical-align: middle;
    i{
      height: 40px;
      width: 40px;
    }
  }
  i{
    margin-right: 25px;
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 41px;
  }
  .inner-content {
    display: none;
  }
}


@media(max-width:767px) {
  .dropdown-content{
    h5{
      span {
        padding-right: 25px;
      }
    }
    i {
      margin-right: 10px;
    }
  }
}



