/*
* footer
*/
#footer{
    background: url("../images/footer-bg.jpg") no-repeat;
    background-size: cover;
    padding: 40px 0 0;
}

@include media-breakpoint-down(sm) {
    padding-top: 30px;
}

/*
* footer-navigation
*/
.footer-navigation {
    margin: 0;
    text-align: center;
    padding: 0 0 43px 0;
    li{
        padding: 0 20px;
        display: inline-block;
        list-style: none;
        a{
            font-weight: 700;
            font-size: rem-calc(12);
            color: rgba(255,255,255,.68);
            text-transform: capitalize;
        }
    }
}

@include media-breakpoint-down(sm) {
    .footer-navigation{
        padding-bottom: 20px;
        li {
            display: block;
            padding: 0;
            margin-bottom: 10px;
        }
    }
}



/*
* foot-note
*/
.foot-note {
    background: url("../images/footnote-bg.png") no-repeat;
    background-size: cover;
    padding: 65px 0 30px;
    ul{
        margin: 0;
        padding: 0;
        text-align: center;
    }
    li{
        &:last-child{
            a{
                margin-right: 0;
                padding-right: 0;
                border-right: none;
            }
        }
        display: inline-block;
        list-style: none;
        a{
            padding-right: 20px;
            margin-right: 25px;
            border-right: 1px solid #6b747a;
            font-weight: 700;
            font-size: rem-calc(11);
            color: rgba(255,255,255,.18);
        }
    }
}

@include media-breakpoint-down(sm) {
    .foot-note{
        padding: 85px 0 40px;
        li{
            display: block;
            margin-bottom: 5px;
            a{
                padding-right: 0;
                margin-right: 0;
                border-right: none;
            }
        }
    }
}






