/*
* slideshow
*/
.slideshow {
    position: relative;
    .slide-image {
        width: 100%;
        object-fit: cover;
        height: 537px;
    }
    .slide, .slides {
        position: relative;
    }
    .slide{
        &:first-child{
            opacity: 1;
        }
        opacity: 0;
        width: 100%;
        position: absolute;
        display: block !important;
        top: 0;
        left: 0;
        width: 100%;
    }
    .slide-description {
        position: absolute;
        top: 50%;
        left: 0;
        padding-left: 120px;
        margin-top: -107px;
    }
    h1{
        font-size: rem-calc(65);
        color: #fff;
        text-transform: uppercase;
    }
    .arrows{
        position: absolute;
        bottom: 110px;
        left: 120px;
        .previous{
            margin-right: 15px;
        }
        .previous, .next {
            display: inline-block;
            vertical-align: middle;
        }
    }
}

@include media-breakpoint-down(lg) {
    .slideshow{
        .slide-description{
            padding-left: 70px;
        }
        .arrows{
            left: 70px;
        }
    }
}

@include media-breakpoint-down(md) {
    .slideshow{
        .slide-description{
            padding-left: 50px;
        }
        .arrows{
            left: 50px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .slideshow{
        .slide-image{
            height: 500px;
        }
        .slide-description{
            padding-left: 15px;
            padding-right: 5px;
        }
        .arrows{
            left: 15px;
        }
        h1{
            font-size: rem-calc(40);
        }
    }
}


@include media-breakpoint-down(xs) {
    .slideshow{
        .arrows{
            bottom: 65px;
        }
    }
}


/*
* mountain
*/
.mountain {
    &:after {
        background: url("../images/mountain.png") no-repeat;
        bottom: 0;
        left: 50%;
        position: absolute;
        width: 526px;
        content: "";
        height: 57px;
        margin-left: -263px;
        z-index: 4;
    }
    position: relative;
}


@include media-breakpoint-down(sm) {
  .slideshow.mountain {
    &:after {
      bottom: -2px;
      background-size: 100% 57px;
    }
  }
}





