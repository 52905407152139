/*
* philosophy-section
*/
.philosophy-section {
    padding: 70px 0;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.3);
    position: relative;
    .container {
        width: 970px;
    }
    h2{
        margin-bottom: 30px;
    }
}

@include media-breakpoint-down(md) {
    .philosophy-section{
        .container{
            width: 730px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .philosophy-section{
      box-shadow: 0px 0px 21px 13px rgba(0, 0, 0, 0.1);
        .container{
            width: 100%;
        }
        p{
          line-height: 26px;
        }
    }
}

/*
* concept
*/
.concept {
    background: url("../images/concept-bg.jpg") repeat;
    padding: 120px 0;
    h2{
        margin-bottom: 40px;
    }
}

@include media-breakpoint-down(sm) {
    .concept{
        padding: 50px 0;
        p {
          line-height: 26px;
        }
    }
}

.specialization {
    margin: 0;
    padding: 5px 0 0px 28px;
    border-left: 6px solid #18c2e7;
    max-width: 760px;
    margin: 0 auto;
    li{
        list-style: none;
        margin-bottom: 20px;
        font-size: rem-calc(16);
        color: #001929;
        line-height: 1.25;
    }
}

@include media-breakpoint-down(sm) {
    .specialization {
        max-width: 100%;
    }
}



/*
* organizational-chart
*/
.organizational-chart {
    padding: 95px 0 120px;
    h2{
        margin-bottom: 50px;
    }
    .chart-image{
        max-width: 810px;
        margin: 0 auto;
    }
}

@include media-breakpoint-down(sm) {
    .organizational-chart{
        padding: 50px 0 80px;
        h2{
            font-size: rem-calc(26);
        }
        .chart-image{
          margin-bottom: 30px;
            img {
              width: 100%;
            }
        }
    }
}

/*
* tour-operators-mountain
*/
.tour-operators.mountain {
    &:after{
        background-image: url("../images/mountain1.png");
        top: -57px;
        bottom: auto;
    }
}

/*
* tour-operators
*/
.tour-operators {
    padding: 105px 0 80px;
    background: #001929;
    h2{
      font-weight: 500;
    }
    .heading1{
        margin-bottom: 100px;
        color: #fff;
        text-align: center;
    }
    .container{
        width: 860px;
    }
    .arrows{
      display: none;
    }
}

.branches{
    padding: 0;
    margin: 0;
    li{
        &:last-child{
            margin-right: 0;
        }
        list-style: none;
        margin-right: 2%;
        float: left;
        width: 32%;
    }
}

@include media-breakpoint-down(sm) {
    .tour-operators{
        padding: 85px 0 20px;
        .container{
            width: 100%;
        }
        .arrows{
          display: block;
          text-align: center;
          .previous {
            margin-right: 15px;
          }
        }
    }
    .branches{
      li{
        width:384px;
        height: 800px;
        margin:0 10px;
        > .box {
          margin-top: 20px;
        }
      }
    }
}

/*
* owners
*/
.owners {
    padding: 100px 0 80px;
    .container{
      width: 970px;
    }
}

@include media-breakpoint-down(sm) {
  .owners{
    .container{
      width: 100%;
    }
  }
}

/*
* reasons-for-booking
*/
.reasons-for-booking {
      background: #ffffff; /* Old browsers */
      background: -moz-linear-gradient(top, #ffffff 0%, #f8fafb 25%, #deebef 60%, #d4e5ea 86%, #c8dde3 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, #ffffff 0%,#f8fafb 25%,#deebef 60%,#d4e5ea 86%,#c8dde3 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, #ffffff 0%,#f8fafb 25%,#deebef 60%,#d4e5ea 86%,#c8dde3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#c8dde3',GradientType=0 ); /* IE6-9 */
      padding: 75px 0 100px;
      .heading1{
        margin-bottom: 70px;
      }
      .container {
        width: 970px;
      }
}

@include media-breakpoint-down(md) {
  .reasons-for-booking{
    .container{
      width: 730px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .reasons-for-booking{
    .container{
      width: 100%;
    }
    h5 {
      i {
        float: left;
      }
      span { display: block;}
    }
  }
}

/*
* team
*/
.team {
  position: relative;
  max-height: 576px;
  .slides {
    height: 576px;
  }
  .slide {
    background: transparent !important;
  }
  .image{
    width: 100%;
  }
  .slide-overlay{
    background: url("../images/team-image-overlay.png") no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
  .slide-description{
    width: 50%;
    padding-left: 0;
    left: 50%;
  }
  .wrap {
    max-width: 400px;
  }
  h2{
    color: #fff;
  }
  p{
    color: #fff;
  }
  .arrows{
    left: 18%;
    margin-left: -95px;
    bottom: 50px;
    .previous{
      margin-right: 0;
    }
    span{
      font-weight: 700;
      font-size: rem-calc(26);
      color: #fff;
      font-family: "Dosis";
      margin: 0 25px;
      display: inline-block;
      vertical-align: middle;
      text-transform: uppercase;
      letter-spacing: 0.75px;
    }
  }
}

@include media-breakpoint-down(md) {
  .team{
    .slide-overlay{
      background-image: url("../images/team-image-overlay1.png");
    }
    .slide-description{
      top: 0;
      left: 0;
      padding: 50px 70px;
      margin-top: 0;
    }
    .slide-description, .wrap {
      width: 100%;
      max-width: 100%;
    }
    .arrows{
      left: 50%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .team{
    .slide-description {
      padding:  35px 15px;
    }
    .slide{
      height: 100%;
    }
    .image{
      position: absolute;
      bottom: 0;
    }
    .arrows{
      bottom: 20px;
      span{
        margin: 0 15px;
      }
    }
  }
}

/*
* our-history
*/
.our-history {
    background: url("../images/concept-bg.jpg") repeat;
    padding: 80px 0 0;
    .heading1{
      margin-bottom: 80px;
    }
    ul{
      margin: 0;
      padding: 0;
    }

}

@media (max-width:991px) {
  .our-history {
    ul {
      max-height: 103px;
      overflow: hidden;
    }
  }
}


/*
* years
*/
.years{
  position: relative;
  .previous{
    &:before{
    background: url("../images/years-before.png") no-repeat;
    width: 137px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -156px;
    content: "";
  }
      position: absolute;
      top: 30px;
      left: -110px;
      z-index: 1;
    }
    .next {
      &:after{
    background: url("../images/years-after.png") no-repeat;
    width: 137px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -156px;
    content: "";
  }

      position: absolute;
      top: 30px;
      right: -110px;
      z-index: 1;
    }

  li{
    &.active {
      &:after{
        background: url("../images/arrow-down.png") no-repeat;
        position: absolute;
        bottom: -10px;
        left: 50%;
        margin-left: -24px;
        width: 49px;
        height: 12px;
        content: "";
        z-index: 1;
      }
    }
    &:last-child{
      margin-right: 0;
    }
    &:hover, &.active {
      background: #11c0e6;
      .year, p {
        color: #fff;
      }
    }
    position: relative;
    width: 160px;
    margin:0 5px;
    float: left;
    text-align: center;
    list-style: none;
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 15%, #ffffff 30%, #fcfcfd 50%, #f8f9f9 67%, #f4f5f6 84%, #ebeef0 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #ffffff 0%,#ffffff 15%,#ffffff 30%,#fcfcfd 50%,#f8f9f9 67%,#f4f5f6 84%,#ebeef0 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ffffff 0%,#ffffff 15%,#ffffff 30%,#fcfcfd 50%,#f8f9f9 67%,#f4f5f6 84%,#ebeef0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ebeef0',GradientType=0 ); /* IE6-9 */
    a{
      padding: 20px 10px 15px 10px;
      display: block;
    }
    .year {
      font-weight: 700;
      font-size: rem-calc(16);
      color: #11c0e6;
      margin-bottom: 10px;
      font-family: "museo-sans";
    }
    p {
      font-weight: 400;
      font-size: rem-calc(12);
      color: #000;
      font-family: "Dosis";
    }
  }
}

@media (max-width:991px) {
  .years {
    padding: 0 90px;
    .previous {
      &:before{
      z-index: -1;
      left: -50px;
      height: 120px;
      bottom: -50px;
      top: auto;
    }
      left: 0px;
    }
    .next {
      &:after {
        z-index: -1;
      right: -50px;
      height: 120px;
      top:auto;
      bottom: -50px;
    }
      right: 0px;
    }
  }
}

@media(max-width:543px) {
  .years{
    padding: 0 50px;
    .previous{
      &:before{
      left: -95px;
    }
      left: -8px;
    }
    .next {
      &:after {
      right: -95px;
    }
      right: -8px;
    }
  }
}



.years{
  .caroufredsel_wrapper{
    width: 1017px !important; margin: 0 auto !important;
  }
}

@media(max-width:1199px) {
  .years{
    li{
      width:145px;
    }
    .caroufredsel_wrapper{
      width: 930px !important;
    }
  }
}


@media(max-width:991px) {
.years{
  li {
    width:160px;
  }
  .caroufredsel_wrapper {
    width:510px !important;
  }
  .previous, .next { display: block !important;}
  }
}


@media(max-width:767px) {
  .years{
    li{
      width:177px;
      margin: 0 7px;
    }
    .caroufredsel_wrapper{
      width:380px !important;
      margin: 0 !important;

    }
  }
}

@media(max-width:599px) {
  .years{
    li{
      width:175px;
    }
  }
}

@media(max-width:499px) {
  .years{
    .previous{
      &:before{
        left: -80px;
      }
    }
    .next{
      &:after {
        right: -84px;
      }
    }
    li{
      width:260px;
      margin: 0 7px;
    }
    .caroufredsel_wrapper{
      width:275px !important;
    }
  }
}

@media(max-width:399px) {
  .years{
    .caroufredsel_wrapper{ width:194px !important;}
    li{
      width:180px;
    }
  }
}





/*
* year-content
*/
.year-content{
  box-shadow: 1px 1px 11px 0px rgba(0,0,0,.1);
  position: relative;
  background: #fff;
  padding: 60px 0 60px;
  .container{
    width: 770px;
  }
  li{
    &:first-child{
      display: block;
    }
    list-style: none;
    display: none;
    float: left;
    width: 100%;
    text-align: center;
  }
}

@include media-breakpoint-down(sm) {
  .year-content {
    box-shadow: 2px 10px 18px 0px rgba(0, 0, 0, 0.1);
    .container {
      width: 100%;
    }
}
}

/*
* year-slideshow
*/
.year-content {
  .year-tab{
    text-align: center;
    .year {
      font-weight: 700;
      font-size: rem-calc(16);
      color: #11c0e6;
      margin-bottom: 10px;
    }
    h4{
      font-weight: 400;
      color: #000;
      font-size: rem-calc(24);
      margin-bottom: 15px;
      text-transform: capitalize;
    }
    p {
      font-size: rem-calc(14);
      font-weight: 300;
      color: #527287;
      line-height: 1.75;
    }
  }
}

.year-tab { display: none; }
.year-tab.active { display: block; }






