
.arrows{
  z-index: 5;
  .fa-angle-right, .fa-angle-left  {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: #fff;
    color: #3e4a57;
    display: inline-block;
    text-align: center;
    font-size: 22px;
    line-height: 24px;
  }
}

.years{
  .arrows{
    .fa-angle-right, .fa-angle-left {
      background: #11bee4;
      color: #fff;
      height: 40px;
      width: 40px;
      font-size: 30px;
      line-height: 34px;
    }
  }
}


.dropdown-content{
  .angle-arrow {
    border: 1px solid #98e4f4;
    padding: 5px;
    font-size: 15px;
    line-height: 17px;
    color: #98e4f4;
    text-align: center;
    position: absolute;
    top: 30px;
    right: 20px;
    height: 30px;
    width: 30px;
    margin-right: 0;
    cursor: pointer;
  }
}

